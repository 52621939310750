<template>
    <div class="Alistofitems">
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="iname" label="项目名称"></el-table-column>
            <el-table-column prop="name" label="用途"></el-table-column>
            <el-table-column prop="money" label="金额"></el-table-column>
            <el-table-column prop="bill" label="票据">
                <template slot-scope="scope">
                    <img :src="scope.row.bill" alt="" width="100" height="80">
                </template>
            </el-table-column>
            <el-table-column prop="time" label="进度">
                <template slot-scope="scope">
                    <el-progress 
                        :percentage="scope.row.rogress" 
                        :color="scope.row.rogress==100?'#67c23a':''"
                        :stroke-width="8"
                    ></el-progress>
                </template>
            </el-table-column>
            <el-table-column prop="results" label="项目成果"></el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data () {
        return {
            pageSize:10,
            totalnum:0,
            currentPage:1,
            tableData:[],
        }
    },
    methods: {
        getapp(){
            this.axios.management({
                params:{
                    schoolId:this.$store.state.schId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.data
                }else{
                    this.tableData = []
                }
            }).catch(err=>{
                
            })
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.Alistofitems .el-table__body-wrapper{
    min-height: calc(100vh - 314px);
}
.Alistofitems .el-table__empty-block{
    min-height: calc(100vh - 314px);
}
</style>